import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-programme',
  templateUrl: './gestion-programme.component.html',
  styleUrl: './gestion-programme.component.css'
})
export class GestionProgrammeComponent {

}
