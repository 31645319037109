import { Component } from '@angular/core';

@Component({
  selector: 'app-messagaries',
  templateUrl: './messagaries.component.html',
  styleUrl: './messagaries.component.css'
})
export class MessagariesComponent {

}
