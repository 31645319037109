<div class="col-md-12">
    <div class="row">
        <div class="col-md-12">
            <div class="navbar">
                <div class="logo-section">
                    <img src="../../../../../assets/img/monkey.png" alt="Monkey Logo" class="logo">
                    <h1><span class="blue">BEE</span> <span class="orange">-KIDS</span></h1>
                </div>
                <div class="nav-links">
                    <a href="#" class="blue">Formateurs</a>
                    <a href="#" class="rose">Établissements</a>
                    <a href="#" class="rose">Pourquoi Nous choisir ?</a>
                </div>
                <button class="login-btn" data-bs-toggle="modal" data-bs-target="#loginModal">Connexion</button>
            </div>
            <app-login></app-login>
        </div>
    </div>
    <div class="pc-container">
        <div class="pc-content">
            <div class="box">
                <img class="vector img-fluid" src=" ../../../../../../assets/img/Vector (1).png"
                    alt="Vector background">
                <h1 class="welcome-text">Bienvenue sur Bee-Kids !</h1>
                <h1 class="text">Gratuit pour les formateurs, pour toujours</h1>
                <img class="line-image img-fluid" src=" ../../../../../../assets/img/Line 6 (1).png" alt="Line 6">

                <button class="login-btn1" data-bs-toggle="modal" data-bs-target="#loginModal">Connectez-vous</button>
                <h1 class="ph">Tout ce dont vous avez besoin<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;pour un classe prospère
                </h1>
                <div class="child-image ">
                    <img class="img-fluid rounded" src=" ../../../../../assets/img/enfant.png" alt="Image of a child">
                </div>
                <div class="rectangle1"></div>
                <div class="rectangle2"></div>
                <div class="rectangle3"></div>

                <div class="image img-fluid"><img class="element" src=" ../../../../../assets/img/ABC.png" alt=""></div>

                <div class="new-image-corner img-fluid">
                    <img src=" ../../../../../assets/img/image9.png" alt="Main Image" class="main-image">
                </div>
                <div class="logo-overlay img-fluid">
                    <img src=" ../../../../../assets/img/logo.png" alt="Logo" class="logo">
                </div>
                <div class="text-overlay">
                    Un monde où les enfants<br> apprennent en s'amusant
                </div>
                <div class="text-overlayy">
                    Créé en 2013, Maternel Kids est un lieu sécurisant et stimulant<br>où un personnel qualifié et
                    attentionné garantit<br>des activités éducatives et ludiques.
                    Nous proposons divers clubs<br>tels que le qoran, le théâtre, le sport, l'anglais et le
                    français.<br>Les enfants peuvent rejoindre différents niveaux:<br>
                    Toute Petite Section (2 ans), Petite Section (3 ans),<br> Moyenne Section (4 ans) et Grande Section
                    (5 ans).
                </div>

                <button class="btn btn-primary login-btn2" data-bs-toggle="modal" data-bs-target="#localisation">voir localisation</button>
                <!-- Modal Structure -->
                
                <div class="modal fade" id="localisation" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content p-4 position-relative">
                            <button type="button" class="btn-close-custom" data-bs-dismiss="modal" aria-label="Close">×</button>
                            <h2 class="modal-title text-center mb-4" id="loginModalLabel">Scannez le QR code pour voir la localisation</h2>
                            <div class="d-flex justify-content-around">
                                <!-- Login option 2 -->
                                <div class="login-option text-center">
                                    <img src="../../../../../assets/img/QR.png" alt="Parent Icon" class="option-icon">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>

                <div class="flech img-fluid">
                    <img src=" ../../../../../assets/img/flech.png" alt="Image of a child">
                </div>
                <div class="card img-fluid">
                    <img src=" ../../../../../assets/img/card.png" alt="Image of a child">
                </div>
                <div class="circlee img-fluid">
                    <img src=" ../../../../../assets/img/circle.png" alt="Image of a child">
                </div>
                <div class="enfants"><img class="enfants img-fluid " src=" ../../../../../assets/img/multi.png" /></div>
            </div>
        </div>
        <footer>
            <app-footer></app-footer>
        </footer>
    </div>
</div>
<div class="footer">
    <div class="circle-group">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
        <div class="circle circle-3"></div>
    </div>
    <div class="footer-background"></div>
    <div class="overlay">
        <div class="overlay-circle overlay-circle-1"></div>
        <div class="overlay-circle overlay-circle-2"></div>
    </div>
    <button class="login-btn3">Notre future</button>
    <div class="title">BEE-KIDS</div>
    <div class="divider"></div>
    <div class="small-circle"></div>
    <div class="orbit-container">
        <div class="orbit-circle orbit-circle-1"></div>
        <div class="orbit-circle orbit-circle-2"></div>
        <div class="orbit-circle orbit-circle-3"></div>
        <div class="orbit-circle orbit-circle-4"></div>
    </div>
    <div class="brand">
        <span class="brand-name">Beehive</span><span class="brand-sub">Entreprises</span>
    </div>
    <img class="logo1" src="../../../../../assets/img/ABC.png" alt="Logo" />
</div>
<div class="wrapper">
    <!-- Contenu principal -->
    <div class="footer1">
        <div class="footer-links d-flex flex-wrap justify-content-center justify-content-md-start">
            <a href="#" class="link mx-1">Carrière</a>
            <a href="#" class="link mx-1">Conditions d'utilisation</a>
            <a href="#" class="link mx-1">Politique de confidentialité</a>
        </div>
        <div class="text-center text-md-end mt-3 mt-md-0">
            ©2024 DPC. Tous droits réservés
        </div>
    </div>
</div>