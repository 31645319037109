import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginServerService } from '../../services/login-server.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { isPlatformBrowser } from '@angular/common'; // Import the helper function
import { InactivityServiceService } from '../../services/inactivity-service.service';
declare var bootstrap: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'] // Fixed the property name from `styleUrl` to `styleUrls`
})
export class LoginComponent implements OnInit {
  public isVisible = false;
  passwordVisible = false;
  public validateForm!: FormGroup;
  userEmail: string = '';
  public loading: boolean = false;
  password?: string;
  selectedImage: string = '';

  constructor(
    private fb: FormBuilder,
    private authProvider: LoginServerService,
    private notification: NzNotificationService,
    private router: Router,
    private modalService: NzModalService,
    private inactivityServiceService:InactivityServiceService,
    @Inject(PLATFORM_ID) private platformId: Object // Inject platformId
  ) {}

  ngOnInit(): void {
      // Start the inactivity timer if the user is authenticated
  if (isPlatformBrowser(this.platformId) && this.authProvider.isAuthenticate()) {
    this.inactivityServiceService.startTimer();
  }
    this.validateForm = this.fb.group({
      userEmail: [
        '',
        [
          Validators.email,
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      userPassword: ['', [Validators.required]],
    });

    // Use isPlatformBrowser to check if the code is running in the browser
    if (isPlatformBrowser(this.platformId) && this.authProvider.isAuthenticate()) {
      this.router.navigate(['admin/users/dashboard']);
    }
  }

  setImage(imageUrl: string) {
    this.selectedImage = imageUrl;
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      this.loading = true;
  
      let auth = { ...this.validateForm.value };
      this.authProvider.Authenticate(auth.userEmail, auth.userPassword).subscribe(
        (res: any) => {
          this.loading = false;
  
          // Use isPlatformBrowser to check if the code is running in the browser
          if (isPlatformBrowser(this.platformId) && this.authProvider.isAuthenticate()) {
            // Get the role from the response or local storage
            let role = res.role || localStorage.getItem('_sid');
            const modal = bootstrap.Modal.getInstance(document.getElementById('loginModalConnecte'));
            if (modal) {
              modal.hide();
            }
  
            // Replace "Formateur" with "Éducateur" in localStorage
            if (role === 'Formateur') {
              role = 'Educateur';
              localStorage.setItem('_sid', role);
            }
  
            // Show Ant Design notification for successful login
            this.notification.create(
              'success',
              'Connexion réussie!',
              'Vous vous êtes connecté avec succès.',
              { nzPlacement: 'bottomLeft', nzDuration: 2000 }
            );
  
            // Redirect based on role with a delay for notification display
            setTimeout(() => {
              switch (role) {
                case 'Directeur':
                case 'Parent':
                case 'Educateur': // Treat "Éducateur" as a valid role
                  this.router.navigate(['/admin/users/dashboard']).then(() => {
                  });
                  break;
                default:
                  console.error('Unknown role or no role found.');
                  break;
              }
            }); 
          }
        },
        (err: any) => {
          this.loading = false;
          this.notification.create(
            'error',
            'Authentification échouée!',
            'Veuillez vérifier vos informations de identification',
            {
              nzPlacement: 'bottomLeft',
              nzDuration: 6000,
            }
          );
        }
      );
    } else {
      // Handle form validation errors
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  

  openModalAndSendRequest(userEmail: string | null): void {
    const emailValue = this.validateForm.get('userEmail')?.value;
    if (emailValue) {
      this.sendPasswordResetRequest(emailValue);
      this.isVisible = true;
    }
  }

  sendPasswordResetRequest(userEmail: string): void {
    this.authProvider.generateResetPasswordToken(userEmail).subscribe(
      (response: any) => {
        console.log('Demande de réinitialisation envoyée avec succès', response);
      },
      (error: any) => {
        console.error('Erreur lors de l\'envoi de la demande de réinitialisation', error);
        // Handle errors if necessary
      }
    );
  }

  handleCancel() {
    this.isVisible = false;
  }
}
