import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-enfants',
  templateUrl: './gestion-enfants.component.html',
  styleUrl: './gestion-enfants.component.css'
})
export class GestionEnfantsComponent {

}
