<div *ngIf="isVisible" class="custom-modal-overlay" id="resetPassword" tabindex="-1" aria-labelledby="resetPassword" aria-hidden="true">
    <div class="custom-modal-box">
      <div class="custom-modal-header">
        <h5>Réinitialisation de Mot de Passe</h5>
        <button type="button" class="custom-close-btn" (click)="handleCancel()">×</button>
      </div>
      <div class="custom-modal-body">
        <form class="custom-form" (ngSubmit)="submitForm()" [formGroup]="validateForm">
  
          <div class="custom-form-group">
            <label for="userEmail">Email :</label>
            <input formControlName="userEmail" id="userEmail" name="userEmail" placeholder="Entrez votre email" />
          </div>
  
          <div class="custom-form-group">
            <label for="resetPasswordToken">Token envoyé dans le mail :</label>
            <input formControlName="resetPasswordToken" id="resetPasswordToken" name="resetPasswordToken" placeholder="Entrez le token" />
          </div>
  
          <div class="custom-form-group">
            <label for="newPassword">Nouveau mot de passe :</label>
            <input formControlName="newPassword" id="newPassword" name="newPassword" type="password" placeholder="Entrez un nouveau mot de passe" />
          </div>
  
          <div class="custom-form-actions">
            <button class="custom-submit-btn" type="submit">
              <i class="ci-plus_circle me-2"></i>
              Envoyer
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  