 <!-- Login Modal -->
 <div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-4 position-relative">
            <button type="button" class="btn-close-custom" data-bs-dismiss="modal" aria-label="Close">×</button>
            <h2 class="modal-title text-center mb-4" id="loginModalLabel">Connectez-vous à Cahier de Liaison</h2>
            <div class="d-flex justify-content-around">
                <!-- Login option 1 -->
                <div class="login-option text-center" data-bs-toggle="modal" data-bs-target="#loginModalConnecte">
                    <img src=" ../../../../../assets/img/image1.png" alt="Formatrice Icon" class="option-icon">
                    <p class="option-label">Formatrice</p>
                </div>
                <!-- Login option 2 -->
                <div class="login-option text-center active" data-bs-toggle="modal"
                    data-bs-target="#loginModalConnecte">
                    <img src=" ../../../../../assets/img/image2.png" alt="Parent Icon" class="option-icon">
                    <p class="option-label">Parent</p>
                </div>
                <!-- Login option 3 -->
                <div class="login-option text-center" data-bs-toggle="modal" data-bs-target="#loginModalConnecte">
                    <img src=" ../../../../../assets/img/image3.png" alt="Directeur Icon" class="option-icon">
                    <p class="option-label">Directeur</p>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- Login Modal -->
<div class="modal fade" id="loginModalConnecte" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog1-centered">
        <div class="modal-content1 p-4 position-relative">
            <button type="button" class="btn-close-custom1" data-bs-dismiss="modal" aria-label="Close">×</button>
            <div class="login-container1">
                <div class="login-box1">
                    <img src=" ../../../../../assets/img/monkey.png" alt="Icone de singe" class="login-icon1">
                    <h2>Se connecter</h2>
                    <p>Connectez-vous à cahier de liaison</p>
                    <form>
                        <label for="username">Nom d'utilisateur</label>
                        <input type="text" id="username" name="username" required>

                        <label for="password">Mot de passe</label>
                        <div class="password-container">
                            <input type="password" id="password" name="password" required>
                            <span class="toggle-password">&#128065;</span>
                        </div>

                        <a href="#" class="forgot-password">mot de passe oublié ?</a>

                        <button type="submit" class="login-button">Connexion</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>