<!-- Login Modal -->
<div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-4 position-relative">
            <button type="button" class="btn-close-custom" data-bs-dismiss="modal" aria-label="Close">×</button>
            <h2 class="modal-title text-center mb-4" id="loginModalLabel">Connectez-vous à BEE-KIDS</h2>
            <div class="d-flex justify-content-around">
                <!-- Login option 1 -->
                <div class="login-option text-center" (click)="setImage('../../../../../assets/img/image1.png')" data-bs-toggle="modal" data-bs-target="#loginModalConnecte">
                    <img src="../../../../../assets/img/image1.png" alt="Formatrice Icon" class="option-icon">
                    <p class="option-label">Educateur</p>
                </div>
                <!-- Login option 2 -->
                <div class="login-option text-center " (click)="setImage('../../../../../assets/img/image2.png')" data-bs-toggle="modal" data-bs-target="#loginModalConnecte">
                    <img src="../../../../../assets/img/image2.png" alt="Parent Icon" class="option-icon">
                    <p class="option-label">Parent</p>
                </div>
                <!-- Login option 3 -->
                <div class="login-option text-center" (click)="setImage('../../../../../assets/img/image3.png')" data-bs-toggle="modal" data-bs-target="#loginModalConnecte">
                    <img src="../../../../../assets/img/image3.png" alt="Directeur Icon" class="option-icon">
                    <p class="option-label">Directeur</p>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <!-- Login Modal for Connected User -->
  <div class="modal fade" id="loginModalConnecte" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="content1 modal-content p-4 position-relative">
            <button type="button" class="btn-close-custom1" data-bs-dismiss="modal" aria-label="Close">×</button>
            <div class="login-container1">
                <div class="login-box1">
                    <img [src]="selectedImage" alt="Selected Option Icon" class="login-icon1" *ngIf="selectedImage">
                    <h2 class="text2">Se connecter</h2>
                    <p class="text1">Connectez-vous à BEE-KIDS</p>
  
                    <!-- Reactive Form for Login -->
                    <form [formGroup]="validateForm" (ngSubmit)="submitForm()">
                        <label for="userEmail" class="nom">Nom d'utilisateur</label>
                        <input type="text" id="userEmail" formControlName="userEmail" class="form-control" required>
                        <div *ngIf="validateForm.controls['userEmail'].invalid && validateForm.controls['userEmail'].touched" class="text-danger">
                            Veuillez entrer un email valide.
                        </div>
  
                        <label for="userPassword" class="nom">Mot de passe</label>
                        <div class="password-container">
                            <input type="password" id="userPassword" formControlName="userPassword" class="form-control" required>
                            <span class="toggle-password" (click)="passwordVisible = !passwordVisible">
                            </span>
                        </div>
                        <div *ngIf="validateForm.controls['userPassword'].invalid && validateForm.controls['userPassword'].touched" class="text-danger">
                            Veuillez entrer un mot de passe.
                        </div>
                        <br>
                        <div class="forgot-password-container">
                            <a class="forgot-password-link" (click)="openModalAndSendRequest(validateForm.get('userEmail')?.value)" data-bs-toggle="modal" data-bs-target="#resetPassword">Mot de passe oublié?</a>
                        </div>
                        <br>
                        <button type="submit" [disabled]="loading" class="login-button">
                            {{ loading ? 'Connexion en cours...' : 'Connexion' }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <app-reset-password *ngIf="isVisible" [isVisible]="isVisible" (handleVisible)="handleCancel()"></app-reset-password>
  