import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-messagaries',
  templateUrl: './gestion-messagaries.component.html',
  styleUrl: './gestion-messagaries.component.css'
})
export class GestionMessagariesComponent {

}
