import { Component } from '@angular/core';

@Component({
  selector: 'app-liste-enfants',
  templateUrl: './liste-enfants.component.html',
  styleUrl: './liste-enfants.component.css'
})
export class ListeEnfantsComponent {

}
