import { Component } from '@angular/core';

@Component({
  selector: 'app-cantine-scolaire',
  templateUrl: './cantine-scolaire.component.html',
  styleUrl: './cantine-scolaire.component.css'
})
export class CantineScolaireComponent {

}
