import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/authentication/pages/login/login.component';
import { RequestPasswordResetComponent } from './features/authentication/pages/request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from './features/authentication/pages/reset-password/reset-password.component';
import { ValidateAccountComponent } from './features/authentication/pages/validate-account/validate-account.component';
import { ParametrageComponent } from './features/layout/components/parametrage/parametrage.component';
import { IndexPageComponent } from './features/layout/index-page/index-page.component';
import { GestionProgrammeComponent } from './pages/administrateur/gestion-jardin/gestion-programme/gestion-programme.component';
import { GestionEvenementComponent } from './pages/administrateur/gestion-jardin/gestion-evenement/gestion-evenement.component';
import { DashboardParentComponent } from './pages/parent/dashboard-parent/dashboard-parent.component';
import { CantineScolaireComponent } from './pages/parent/cantine-scolaire/cantine-scolaire.component';
import { ListeEnfantsComponent } from './pages/parent/liste-enfants/liste-enfants.component';
import { MessagariesComponent } from './pages/parent/messagaries/messagaries.component';
import { ProgrammeComponent } from './pages/parent/programme/programme.component';
import { NotificationsComponent } from './pages/parent/notifications/notifications.component';
import { GestionMessagariesComponent } from './pages/enseignant/gestion-messagaries/gestion-messagaries.component';
import { GestionEnfantsComponent } from './pages/enseignant/gestion-enfants/gestion-enfants.component';
import { GestionEvaluationComponent } from './pages/enseignant/gestion-evaluation/gestion-evaluation.component';
import { ModalsComponent } from './core/components/modals/modals.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    ModalsComponent,
    LoginComponent,
    RequestPasswordResetComponent,
    ResetPasswordComponent,
    ValidateAccountComponent,
    ParametrageComponent,
    IndexPageComponent,
    GestionProgrammeComponent,
    GestionEvenementComponent,
    DashboardParentComponent,
    CantineScolaireComponent,
    ListeEnfantsComponent,
    MessagariesComponent,
    ProgrammeComponent,
    NotificationsComponent,
    GestionMessagariesComponent,
    GestionEnfantsComponent,
    GestionEvaluationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NzModalModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NzNotificationModule,
    RouterModule.forRoot([]), // Ensure this is configured
  ],
  providers: [
    provideClientHydration(),
    [provideHttpClient(withFetch())],
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]  // Enables support for custom elements
})
export class AppModule { }
