import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { LoginServerService } from '../../services/login-server.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
 
  @Input() isVisible: boolean = false;
  @Output() handleVisible: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetComplete: EventEmitter<void> = new EventEmitter<void>();  // Add this line

  public loading: boolean = false;
  validateForm!: FormGroup;
  selectedValue = null;
  userEmail: string = '';
  resetPasswordToken : string = '';
  newPassword : string = '';

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private authProvider: LoginServerService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userEmail: ["", [Validators.required]],
      newPassword: ["", [Validators.required]],
      resetPasswordToken: ["", [Validators.required]],
    });
  }
     
  handleCancel(): void {
    this.handleVisible.emit();
  }

  submitForm() {
    if (this.validateForm.valid) {
      this.loading = true;
      const userData = { ...this.validateForm.value };

      this.authProvider.resetPassword(userData).subscribe(
        (res) => {
          this.notification.create(
            'success',
            "Mot de passe récupéré",
            'Votre mot de passe a été réinitialisé avec succès!',
            { nzPlacement: 'bottomLeft' }
          );
          this.loading = false;

          // Emit resetComplete event
          this.resetComplete.emit();

          this.handleCancel();
        },
        (err) => {
          this.notification.create(
            'error',
            'Erreur',
            'Veuillez vérifier vos données!',
            { nzPlacement: 'bottomLeft' }
          );
          this.loading = false;
        }
      );
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  ngOnDestroy(){
    this.validateForm.reset();
  }
}
