import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavbarComponent } from './features/layout/components/navbar/navbar.component';
import { FooterComponent } from './features/layout/components/footer/footer.component';
import { IndexPageComponent } from './features/layout/index-page/index-page.component';
import { ParentsComponent } from './pages/administrateur/gestion-utlisateurs/parents/parents.component';
import { HeaderComponent } from './features/layout/components/header/header.component';
import { SidebarComponent } from './features/layout/components/sidebar/sidebar.component';
import { UploadComponent } from './features/layout/components/upload/upload.component';
import { LoginComponent } from './features/authentication/pages/login/login.component';
import { RequestPasswordResetComponent } from './features/authentication/pages/request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from './features/authentication/pages/reset-password/reset-password.component';
import { ValidateAccountComponent } from './features/authentication/pages/validate-account/validate-account.component';
import { authBlockGuard } from './core/guards/auth-block.guard';
import { ProfilComponent } from './pages/administrateur/profil/profil.component';
import { DashboardsAdminComponent } from './pages/administrateur/dashboard/dashboards-admin/dashboards-admin.component';


const routes: Routes = [

  {path: 'profil', component :ProfilComponent,canActivate: [authBlockGuard] },

  

  // Authentication Routing Pages
  { path: 'login', component: LoginComponent,canActivate: [authBlockGuard]},
  { path: 'request-password-reset', component: RequestPasswordResetComponent,canActivate: [authBlockGuard] },
  { path: 'reset-password/:token', component: ResetPasswordComponent,canActivate: [authBlockGuard] },
  { path: 'validate-account/:token', component: ValidateAccountComponent ,canActivate: [authBlockGuard]},
  { 
   path: 'admin', 
   loadChildren: () => import('../../src/app/features/layout/layout.module').then(m => m.LayoutModule) 
 },
 
  {path: 'home', component :IndexPageComponent },
   { path: '**', redirectTo: '/home' },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
